<template>
  <section>
    <q-permissions :__PERMISSIONS__="permStatus" :page="'relance'" :index="'index'" />
    <div class="" v-if="permStatus">
      <!-- Tableau pour afficher les taxes -->
      <div class="">
        <b-card no-body class="py-2">
          <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->
          <b-row class="mx-2">
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
            >
              <label>Entrées</label>
              <v-select
                v-model="state.perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
              <b-button
                variant="primary"
                @click="__ACTION__('e-add-taxe')"
                v-b-modal.e-add-taxe
              >
                <q-button-created title="Nouvelle taxe" />
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6" class="mt-1">
              <div class="d-flex align-items-center justify-content-end">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="state.filtre"
                    class="d-inline-block"
                    placeholder="Rechercher par : nom de taxe, pourcentage, code..."
                  />
                </b-input-group>
              </div>
            </b-col>
          </b-row>

          <q-loader-table
            :success="state.success"
            :empty="state.empty"
            :warring="state.warring"
          />
          <b-table
            hover
            responsive
            primary-key="id"
            :per-page="state.perPage"
            :current-page="state.currentPage"
            :items="taxes"
            :fields="tableColumns"
            :filter="state.filtre"
            show-empty
            empty-text="Aucune taxe enregistrée"
            class="bg-white"
            v-if="state.success === true"
          >
            <template #cell(value)="data" class="py-50">
              <span> {{ data.item.valeur }} % </span>
            </template>

            <template #cell(statut)>
              <b-badge
                variant="light-success"
                class="text-uppercase font-weight-bolder d-inline-flex items-content-center"
                style="font-size: 12px !important"
              >
                Activé
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="flex text-nowrap py-1">
                <!-- <feather-icon
                @click="__ACTION__MODE__('e-add-taxe', data.item)"
                icon="Edit3Icon"
                size="16"
                class=""
                v-b-modal.e-edit-taxe
                disabled
              /> -->

                <feather-icon
                  icon="TrashIcon"
                  class="mx-1"
                  size="16"
                  disabled
                  @click="deleteTaxe(data.item.id)"
                />

                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                  disabled
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item> -->
                  <b-dropdown-item disabled>
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50"> Supprimer</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <!-- Les boutons de pagination -->
          <div class="mx-2 mb-2 pb-1">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"></span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="state.currentPage"
                  :total-rows="taxes.length"
                  :per-page="state.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>

      <q-taxe-actions :actionMode="actionMode" :data="taxeDataAction" :taxes="taxes" />

      {{ __NEXT }}
    </div>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import URL from "@/views/pages/request";
import axios from "axios";
import { computed, onMounted, reactive, ref } from "@vue/composition-api";
import moment from "moment";
import QTaxeActions from "./qTaxeActions.vue";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import { toast_destory } from "@/utils/qToast";
import qDeleteAtUtils from "@/utils/qDeleteAtUtils";
import QPermissions from "../../access/qPermissions.vue";
import QButtonCreated from '@/components/qButtonCreated.vue';

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BLink,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    required,
    email,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    axios,
    QTaxeActions,
    QLoaderTable,
    QPermissions,QButtonCreated
  },
  directives: {
   Ripple,
  },

  setup(props, { root }) {
    const state = reactive({
      perPage: 30,
      filtre: "",
      currentPage: 1,
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const perPageOptions = [30, 50, 100, 150, 250, 500];
    const tableColumns = [
      {
        key: "code",
        label: "Code",
      },
      {
        key: "libelle",
        label: "Libelle",
      },
      {
        key: "value",
        label: "Pourcentage",
      },
      {
        key: "statut",
        label: "Statut",
      },
      {
        key: "created_at",
        label: "date d'ajout",
      },
      {
        key: "actions",
        label: "Actions",
      },
    ];
    const taxes = ref([]);
    const actionMode = ref("");
    const taxeDataAction = ref({});
    const __GIVE = ref(false);

    onMounted(() => {
      document.title = "Taxes - Ediqia";
      getAllTaxes();
    });

    const permStatus = computed(() => {
      return root.$store.state.qPermissions.statuts;
    });

    // Get all
    const getAllTaxes = async () => {
      try {
        const { data } = await axios.get(URL.TAXE_LIST);

        if (data) {
          console.log(data);
          taxes.value = data.taxes;
          taxes.value.forEach((taxe) => {
            taxe.created_at = moment(taxe.created_at).format("DD-MM-YYYY");
          });
          taxes.value = qDeleteAtUtils.delete_update_at(taxes.value);
          taxes.value.reverse();
          __GIVE.value = true;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const __NEXT = computed(() => {
      if (__GIVE.value === true) {
        taxes.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
      return;
    });

    const deleteTaxe = async (id) => {
      root
        .$swal({
          title: `Êtes vous sûr !?`,

          text: "Aucun retour en arriére ne sera posible",

          icon: "warning",

          showCancelButton: true,

          confirmButtonText: "Oui",

          customClass: {
            confirmButton: "btn btn-primary",

            cancelButton: "btn btn-outline-danger ml-1",
          },

          buttonsStyling: false,
        })
        .then(async (response) => {
          if (response.isConfirmed === true) {
            try {
              const { data } = await axios.post(URL.TAXE_DESTROY, { id: id });
              if (data) {
                taxes.value = taxes.value.filter((taxe) => {
                  return taxe.id !== id;
                });
                toast_destory(
                  root,
                  "success",
                  "top-right",
                  `Article supprimer avec succès !`
                );
              }
            } catch (error) {
              console.log(error);
            }
          }
        });
    };

    //
    const __ACTION__MODE__ = (mode, data) => {
      actionMode.value = mode;
      taxeDataAction.value = data;
    };

    const __ACTION__ = (mode) => {
      actionMode.value = mode;
    };

    return {
      state,
      perPageOptions,
      tableColumns,
      actionMode,
      taxeDataAction,
      taxes,
      deleteTaxe,
      __ACTION__MODE__,
      __ACTION__,
      __NEXT,
      permStatus,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}
.none {
  display: none;
}
.block {
  display: inline-block;
}
</style>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
